<template>
  <div>
    <TitleBar
      :top-title="title"
      :top-left="''"
    />
    <div style="margin:20vh auto 50px; width:300px; font-size:1.5rem; font-weight:bold; text-align:right">
      <table style="table-layout:unset; border-spacing:5px">
        <tr>
          <td>아이디</td>
          <td>
            <input
              v-model="id"
              type="text"
              style="width:200px; text-align:center"
            >
          </td>
        </tr>
        <tr>
          <td>비밀번호</td>
          <td>
            <input
              v-model="pw"
              type="password"
              style="width:200px; text-align:center"
              @keyup.enter="onClickSignin"
            >
          </td>
        </tr>
      </table>
    </div>
    <input
      type="button"
      :value="getLang('로그인')"
      style="margin:0 auto; padding:5px 30px; height:unset; font-size:1.5rem; font-weight:bold"
      @click="onClickSignin"
    >
    <div style="margin-top:10px; height:20px">
      <div
        class="wrong"
        style="position:absolute; width:100%; color:red; font-weight:bold; text-align:center; display:none"
      >
        wrong...
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import TitleBar from '@/components/navi/TitleBar.vue';
import axios from 'axios';

export default {
  metaInfo () {
    return {
      title: this.title,
    };
  },
  components: {
    TitleBar,
  },
  data () {
    return {
      id: '',
      pw: '',
    };
  },
  computed: {
    ...mapGetters({
      getLang: 'getLang'
    }),
    title () {
      return this.getLang('XR Magic');
    },
  },
  beforeDestroy () {
  },
  mounted () {
    this.$cookies.remove('token');
    this.clearBasic();
  },
  methods: {
    ...mapMutations({
      clearBasic: 'clearBasic',
    }),
    async onClickSignin () {
      const rs = await axios.post(`${this.$store.state.apiHost}/user/signIn`, {
        id: this.id,
        pw: this.pw,
      });

      if (rs.data.result == 'OK') {
        this.clearBasic();
        this.$router.replace({ path: '/' });
      } else {
        $('.wrong').show().fadeOut(2000);
      }
    }
  }
};
</script>

<style scoped>
  input {
    margin-left: 5px;
    height: 30px;
  }
</style>
